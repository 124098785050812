<template>
  <div>
    <v-btn class="mt-2" small color="primary" @click="companyDialog = true"
      >Assign to Company</v-btn
    >
    <v-dialog v-model="companyDialog" max-width="800px">
      <v-card
        ><v-card-title> <h2>Assign client's to a company</h2> </v-card-title>
        <div class="pa-4">
          <p :style="{ textAlign: 'center' }">
            Select the client's from the table first
          </p>
          <alt-company-picker @company-selected="setCompanyName" />
        </div>

        <div class="pa-2 text-right">
          <v-btn color="secondary" @click="companyDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="updateUsersCompany">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import Swal from "sweetalert2";
import AltCompanyPicker from "../../../components/Base/Pickers/AltCompanyPicker.vue";

export default {
  name: "AssignCompanyToUser",
  components: { AltCompanyPicker },
  props: {
    userIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      companyDialog: false,
      companyName: null,
    };
  },
  methods: {
    openTheDialog() {
      this.companyDialog = true;
    },
    closeTheDialog() {
      this.companyDialog = false;
    },
    setCompanyName(companyName) {
      this.companyName = companyName;
    },
    async updateUsersCompany() {
      try {
        if (!this.companyName) {
          Swal.fire({
            icon: "error",
            title: "Select Company",
            text: "Please select a company before saving.",
          });
          return;
        }

        const UPDATE_USERS_COMPANY = gql`
          mutation assignUsersToCompanies(
            $userIds: [String!]!
            $companyName: String!
          ) {
            assignUsersToCompanies(
              userIds: $userIds
              companyName: $companyName
            ) {
              success
              message
            }
          }
        `;

        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_USERS_COMPANY,
          variables: {
            userIds: this.userIds,
            companyName: this.companyName,
          },
        });

        if (data && data.assignUsersToCompanies.success) {
          Swal.fire({
            icon: "success",
            title: "Consultants assigned",
            text: "Clients have been successfully assigned to their consultant.",
          })
            .then(() => {
              this.$emit("company-assigned");
            })
            .finally(() => {
              this.companyDialog = false;
            });
        } else {
          console.error("Failed to assign users");

          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Failed to assign users. Please try again later.",
          });
        }
      } catch (error) {
        console.error("Error updating users consultant:", error);
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while updating users. Please try again later.",
        });
      }
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
